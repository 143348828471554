<template>
    <Card title="Works">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Title</th>
                    <th style="width: 28px">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in works" :key="item.id">
                    <td>{{ item.title }}</td>
                    <td style="width: 28px"><button role="button" class="btn btn-link btn-sm" @click="$router.push({ name: 'AdminWorksEdit', params: { item } })">edit</button></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="99">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-outline-success" @click="$router.push({ name: 'AdminWorksEdit' })">
                                <i class="fas fa-plus"></i> CREATE NEW PROJECT
                            </button>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </Card>
</template>

<script>
import firebase from '../../../firebase'
import Card from '@/components/admin/Card.vue'
import Overlay from '@/components/admin/Overlay.vue'
export default {
    components: { Card, Overlay },
    data() {
        return {
            works: []
        }
    },
    methods: {
    },
    mounted() {
        firebase.firestore().collection('projects').onSnapshot(({ docs }) => {
            this.works = docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            })
        });
    }
}
</script>

<style scoped>
.item {
    width: 280px;
    height: 240px;
    flex-wrap: wrap;
}
</style>